import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CustomerDefault = Loadable(lazy(() => import('views/customer/index')));
const Trc20Wallet = Loadable(lazy(() => import('views/wallet/trc20Wallet')));
const TRXWallet = Loadable(lazy(() => import('views/wallet/trxWallet')));
const USDCWallet = Loadable(lazy(() => import('views/wallet/usdcWallet')));
const BTCWallet = Loadable(lazy(() => import('views/wallet/btcWallet')));
const DOGEWallet = Loadable(lazy(() => import('views/wallet/dogeWallet')));
const XRPWallet = Loadable(lazy(() => import('views/wallet/xrpWallet')));
const ETHWallet = Loadable(lazy(() => import('views/wallet/ethWallet')));
const LedgerDefault = Loadable(lazy(() => import('views/ledger/transfer')));
const AdminReportDefault = Loadable(lazy(() => import('views/report/admin')));
const LogRequest = Loadable(lazy(() => import('views/report/log')));
const CustomerReportDefault = Loadable(lazy(() => import('views/report/customer')));
const WalletReportDefault = Loadable(lazy(() => import('views/report/wallet')));
const MonthlyReport = Loadable(lazy(() => import('views/report/monthly')));
const SuccessReport = Loadable(lazy(() => import('views/report/success')));
const MainSettingDefault = Loadable(lazy(() => import('views/settings/mainSetting')));
// const ManualRequest = Loadable(lazy(() => import('views/settings/request')));
// const ProfileDefault = Loadable(lazy(() => import('views/profile/profile')));
const ChangePaasowrdDefault = Loadable(lazy(() => import('views/profile/changePassword')));
// const ErrorDefault = Loadable(lazy(() => import('views/error/Default')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/customer',
            element: <CustomerDefault />
        },
        {
            path: '/trc20-wallets',
            element: <Trc20Wallet />
        },
        {
            path: '/btc-wallets',
            element: <BTCWallet />
        },
        {
            path: '/eth-wallets',
            element: <ETHWallet />
        },
        {
            path: '/doge-wallets',
            element: <DOGEWallet />
        },
        {
            path: '/xrp-wallets',
            element: <XRPWallet />
        },
        {
            path: '/trx-wallets',
            element: <TRXWallet />
        },
        {
            path: '/usdc-wallets',
            element: <USDCWallet />
        },
        {
            path: '/ledger-transfer',
            element: <LedgerDefault />
        },
        {
            path: '/customer-report',
            element: <CustomerReportDefault />
        },
        {
            path: '/wallet-report',
            element: <WalletReportDefault />
        },
        {
            path: '/admin-report',
            element: <AdminReportDefault />
        },
        {
            path: '/transaction-report',
            element: <SuccessReport />
        },
        {
            path: '/payment-report',
            element: <MonthlyReport />
        },
        {
            path: '/main-setting',
            element: <MainSettingDefault />
        },
        {
            path: '/log',
            element: <LogRequest />
        },
        // {
        //     path: '/profile',
        //     element: <ProfileDefault />
        // },
        {
            path: '/change-password',
            element: <ChangePaasowrdDefault />
        }
    ]
};

export default MainRoutes;
