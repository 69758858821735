const preState = {
    isAuth: false,
    userData: {},
    OTP: {},
    authToken: ""
}

const auth = (state = preState, action) => {
    switch (action.type) {
        case "SET_OTP":
            return {
                ...state,
                OTP: action.data,
            }
        case "SET_TOKEN":
            return {
                ...state,
                authToken: action.data,
            }
        case "set-userdata":
            return {
                ...state,
                userData: action.data
            }
        default:
            state = { ...state }
            break
    }
    return state
}

export default auth;