import baseConfig from "./baseConfig"
import ApiConfig from "./apiConfig"
import themeConfig from "./themeConfig"

const Api = new ApiConfig();

const rdata = {
    Api,
    ...baseConfig,
    ...themeConfig,
}

export default rdata;
