import Config from "../../../config/index"

export const userLogin = async (sendData) => {
	const data = await Config.Api.userLogin(sendData)
	return data.data
}

export const confirmAuth = async (authCode, token, addToast) => {
	const data = await Config.Api.confirmAuthCode({ authCode, token })
	if (data.data.status) {
		addToast(`Hoşgeldiniz.`, { appearance: 'success', autoDismiss: true });
		Config.Api.setToken(data.data.member.token)
		setTimeout(() => {
			window.location.assign('/')
		}, 500)
	} else {
		addToast(data.data.message, { appearance: 'warning', autoDismiss: true });
	}
}

export const submitChangePassword = async (sendData, addToast) => {
	const data = await Config.Api.submitChangePassword(sendData)
	if (data.data.status) {
		addToast(`Başarılı.`, { appearance: 'success', autoDismiss: true });
	} else {
		addToast(data.data.data, { appearance: 'error', autoDismiss: true });
	}
}

export const sessionCheck = async () => {
	const url = window.location.pathname
	if (url !== "/payment") {
		const token = Config.Api.getToken()
		if (token) {
			const ScData = await Config.Api.sessionCheck()
			if (ScData.data.status) {
				return {
					Auth: { isAuth: true, userData: ScData.data.data }
				}
			}
			return {
				Auth: { isAuth: false }
			}
		}
	}
	return {
		Auth: { isAuth: false }
	}
}

export const checkUserData = async (customer, isNew) => {
	if (!customer.username) {
		return "lütfen kullanıcı adını girin"
	}
	if (customer.username.length < 5) {
		return "kullanıcı adı 5'ten uzun olmalı"
	}
	if (customer.username.length > 24) {
		return "kullanıcı adı 24'ten kısa olmalıdır"
	}
	if (!customer.email) {
		return "lütfen e-posta girin"
	}
	if (!/[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,8}/igm.test(customer.email)) {
		return "lütfen doğru e-posta girin"
	}
	if (!customer.callbackUrl) {
		return "lütfen callbackUrl girin"
	}
	if (!customer.callbackUrl) {
		return "lütfen callbackUrl girin"
	}
	if (!customer.website) {
		return "lütfen web sitesini girin"
	}
	if (!customer.authkey) {
		return "lütfen authkey girin"
	}
	if (!customer.ips) {
		return "lütfen ips girin"
	}
	if (isNew) {
		if (!customer.customerId) {
			return "lütfen müşteri kimliğini girin"
		}
		if (customer.customerId.length < 5) {
			return "customerId 5'ten uzun olmalıdır"
		}
		if (customer.customerId.length > 24) {
			return "müşteri kimliği 24'ten kısa olmalıdır"
		}
		if (customer.password && customer.password.length < 8) {
			return "Parola uzunluğu 8'den fazla olmalıdır"
		}
		if (customer.password !== customer.rpassword) {
			return "şifreyi onayla yanlış"
		}
	}
	return true
}