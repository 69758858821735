import { combineReducers } from 'redux';
import AuthReducer from './auth/index';
import customizationReducer from './customization/index';
import reportReducer from './report/index';

const rootReducer = combineReducers({
  Auth: AuthReducer,
  customization: customizationReducer,
  report: reportReducer,
});

export default rootReducer;
