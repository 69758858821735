import { IconUsers, IconDashboard, IconAdjustments } from '@tabler/icons';

const menuItems = {
    items: [
        {
            id: 'main menu',
            title: 'Menu Links',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Anasayfa',
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'customerReport',
                    title: 'Customer Report',
                    type: 'item',
                    url: '/customer-report',
                    icon: IconUsers,
                    breadcrumbs: true
                },
                {
                    id: 'walletReport',
                    title: 'Cüzdan Raporları',
                    type: 'item',
                    url: '/wallet-report',
                    icon: IconAdjustments,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default menuItems;
