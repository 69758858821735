import {
    IconDashboard, IconUserCircle, IconWallet, IconAdjustments, IconUsers, IconReport, IconTrophy, IconSettings, IconWallpaper,
    IconCommand, IconReportAnalytics, IconCashBanknote, IconFileCheck
} from '@tabler/icons';

import { Usdt, Btc, Eth, Trx, Usdc, Doge, Xrp } from '@styled-icons/crypto'

const menuItems = {
    items: [
        {
            id: 'main menu',
            title: 'Menü',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Anasayfa',
                    type: 'item',
                    url: '/dashboard',
                    icon: IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'customer',
                    title: 'Müşteriler',
                    type: 'item',
                    url: '/customer',
                    icon: IconUserCircle,
                    breadcrumbs: false
                },
                {
                    id: 'wallets',
                    title: 'Cüzdanlar',
                    type: 'collapse',
                    icon: IconWallet,
                    children: [
                        {
                            id: 'trc20Wallets',
                            title: 'USDT',
                            type: 'item',
                            url: '/trc20-wallets',
                            icon: Usdt,
                            breadcrumbs: true
                        },
                        {
                            id: 'trxWallets',
                            title: 'TRX',
                            type: 'item',
                            url: '/trx-wallets',
                            icon: Trx,
                            breadcrumbs: true
                        },
                        {
                            id: 'usdcWallets',
                            title: 'USDC',
                            type: 'item',
                            url: '/usdc-wallets',
                            icon: Usdc,
                            breadcrumbs: true
                        },
                        {
                            id: 'btcWallets',
                            title: 'BTC',
                            type: 'item',
                            url: '/btc-wallets',
                            icon: Btc,
                            breadcrumbs: true
                        },
                        {
                            id: 'ethWallets',
                            title: 'ETH',
                            type: 'item',
                            url: '/eth-wallets',
                            icon: Eth,
                            breadcrumbs: true
                        },
                        {
                            id: 'dogeWallets',
                            title: 'DOGE',
                            type: 'item',
                            url: '/doge-wallets',
                            icon: Doge,
                            breadcrumbs: true
                        },
                        {
                            id: 'xrpWallets',
                            title: 'XRP',
                            type: 'item',
                            url: '/xrp-wallets',
                            icon: Xrp,
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'Report',
                    title: 'Raporlar',
                    type: 'collapse',
                    icon: IconReport,
                    children: [
                        {
                            id: 'adminReport',
                            title: 'Admin Raporları',
                            type: 'item',
                            url: '/admin-report',
                            icon: IconTrophy,
                            breadcrumbs: true
                        },
                        {
                            id: 'customerReport',
                            title: 'Müşteri Raporları',
                            type: 'item',
                            url: '/customer-report',
                            icon: IconUsers,
                            breadcrumbs: true
                        },
                        {
                            id: 'walletReport',
                            title: 'Cüzdan Raporları',
                            type: 'item',
                            url: '/wallet-report',
                            icon: IconAdjustments,
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'Advanced',
                    title: 'Gelişmiş Raporlar',
                    type: 'collapse',
                    icon: IconReportAnalytics,
                    children: [
                        {
                            id: 'paymentReport',
                            title: 'Ödeme Raporu',
                            type: 'item',
                            url: '/payment-report',
                            icon: IconCashBanknote,
                            breadcrumbs: true
                        },
                        {
                            id: 'transactionReport',
                            title: 'İşlem Adet Raporu',
                            type: 'item',
                            url: '/transaction-report',
                            icon: IconFileCheck,
                            breadcrumbs: true
                        }
                    ]
                },
                {
                    id: 'ledgerTransfer',
                    title: 'Ledger Transfer',
                    type: 'item',
                    url: '/ledger-transfer',
                    icon: IconWallpaper,
                    breadcrumbs: false
                },
                {
                    id: 'log',
                    title: 'İşlem Geçmişi',
                    type: 'item',
                    url: '/log',
                    icon: IconCommand,
                    breadcrumbs: false
                },
                {
                    id: 'Setting',
                    title: 'Ayarlar',
                    type: 'collapse',
                    icon: IconSettings,
                    children: [
                        {
                            id: 'mainsetting',
                            title: 'Ayarlar',
                            type: 'item',
                            url: '/main-setting',
                            icon: IconSettings,
                            breadcrumbs: true
                        }
                        // {
                        //     id: 'Manual Request',
                        //     title: 'Manual Request',
                        //     type: 'item',
                        //     url: '/manual-request',
                        //     icon: IconSettings,
                        //     breadcrumbs: true
                        // },
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
