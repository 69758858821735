const preState = {
    customer: {
        data: [],
        totalRecords: 0,
        sortIndex: [0, 0]
    },
    wallet: {
        data: [],
        totalRecords: 0,
        sortIndex: [0, 0]
    }
}

const report = (state = preState, action) => {
    switch (action.type) {
        case "CUSTOMER_REPORT_LIST":
            return {
                ...state,
                customer: {
                    data: action.data.list,
                    totalRecords: action.data.pages.totalRecords,
                    sortIndex: [action.data.pages.skip1, action.data.pages.skip2]
                }
            }
        case "WALLET_REPORT_LIST":
            return {
                ...state,
                wallet: {
                    data: action.data.list,
                    totalRecords: action.data.pages.totalRecords,
                    sortIndex: [action.data.pages.skip1, action.data.pages.skip2]
                }
            }
        default:
            state = { ...state }
            break
    }
    return state

}

export default report;