import { lazy } from 'react';
import CustomerLayout from 'layout/CustomerLayout';
import Loadable from 'ui-component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const CustomerReportDefault = Loadable(lazy(() => import('views/report/customer')));
const WalletReportDefault = Loadable(lazy(() => import('views/report/wallet')));
const ChangePaasowrdDefault = Loadable(lazy(() => import('views/profile/changePassword')));
const ErrorDefault = Loadable(lazy(() => import('views/error/Default')));

const MainRoutes = {
    path: '/',
    element: <CustomerLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/customer-report',
            element: <CustomerReportDefault />
        },
        {
            path: '/wallet-report',
            element: <WalletReportDefault />
        },
        {
            path: '/change-password',
            element: <ChangePaasowrdDefault />
        },
        {
            path: '*',
            element: <ErrorDefault />
        }
    ]
};

export default MainRoutes;

// {
//     path: '/profile',
//     element: <ProfileDefault />
// },
