import { useTheme } from '@mui/material/styles';
import { Box, Avatar } from '@mui/material';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import { IconMenu2 } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU } from 'redux/reducers/customization/actions';

const Header = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const handleToggleButton = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    }

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        bgcolor: '#ede7f6',
                        color: '#5E49AB',
                        fontSize: '1.2rem',
                        width: '34px',
                        height: '34px',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out 0s',
                        "&:hover": {
                            bgcolor: '#5E49AB',
                            color: '#ede7f6'
                        }
                    }}
                    onClick={handleToggleButton}
                >
                    <IconMenu2 style={{ width: '20px', height: '20px' }} />
                </Avatar>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <ProfileSection />
        </>
    );
};

export default Header;
