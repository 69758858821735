import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ClickAwayListener, Divider, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Stack, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/bitcoin.dd8a16.png';
import { IconLogout, IconSettings } from '@tabler/icons';

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const userData = useSelector((state) => state.Auth.userData);
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        localStorage.clear()
        window.location.reload()
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box sx={{ display: "flex", alignItems: 'center', cursor: 'pointer' }} onClick={handleToggle}>
                <Box sx={{ pt: 2, pr: 2 }}>
                    <Stack>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="h5">Hoşgeldin,</Typography>
                            <Typography component="span" variant="h5" sx={{ fontWeight: 400 }}>
                                {userData?.username}
                            </Typography>
                        </Stack>
                        {/* <Typography variant="subtitle1">
                            {userData ? userData.balance.toLocaleString('tr-TR', {style: 'currency', currency: 'TRY'}) : "₺0.00"}
                        </Typography> */}
                    </Stack>
                </Box>
                <Avatar
                    src={User1}
                    sx={{
                        cursor: 'pointer'
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                />
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                maxWidth: 350,
                                                minWidth: 200,
                                                borderRadius: '4px'
                                            }}
                                        >
                                            {/* <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1, '/profile')}
                                            >
                                                <ListItemIcon>
                                                    <IconUser stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">My Profile</Typography>} />
                                            </ListItemButton>
                                            <Divider /> */}
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1, '/change-password')}
                                            >
                                                <ListItemIcon>
                                                    <IconSettings stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Şifre Değiştir</Typography>} />
                                            </ListItemButton>
                                            <Divider />
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                selected={selectedIndex === 4}
                                                onClick={() => handleLogout()}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Çıkış</Typography>} />
                                            </ListItemButton>
                                            <Divider />
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
