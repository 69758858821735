import { io } from "socket.io-client"
import Config from "../../../config/index"

const baseInit = async (userData) => {
    Config.Root.socket = io(Config.Root.socketServerUrl, { transports: ['websocket'] });

    Config.Root.socket.off("sessionExpire")
    Config.Root.socket.on("sessionExpire", (data) => {
        // eslint-disable-next-line
        if (userData && userData['_id'] === data.userId) {
            Config.Api.clearToken()
            window.location.assign("/")
        }
    })
}

export default baseInit