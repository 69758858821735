import { useRoutes } from 'react-router-dom';

// eslint-disable-next-line
import MainRoutes from './MainRoutes';
// eslint-disable-next-line
import AuthenticationRoutes from './AuthenticationRoutes';
// eslint-disable-next-line
import ServiceRoutes from './ServiceRoutes';
import config from 'config/themeConfig';

export default function ThemeRoutes() {
    return useRoutes([ServiceRoutes], config.themeConfig.basename);
    // this is for payment site

    //return useRoutes([AuthenticationRoutes, MainRoutes], config.themeConfig.basename);
    // this is for admin site

    //return useRoutes([AuthenticationRoutes, ServiceRoutes, MainRoutes], config.themeConfig.basename);
    // all
}