const themeConfig = {
    basename: '/',
    defaultPath: '/customer-report',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 400
};

const customerModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: 800
};

const customerStatusList = [
    { value: "", label: "All" },
    { value: true, label: "Enable" },
    { value: false, label: "Disable" }
]

export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export default {
    themeConfig,
    modalStyle,
    customerModalStyle,
    customerStatusList,

    gridSpacing,
    drawerWidth,
    appDrawerWidth
}