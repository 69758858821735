import { lazy } from 'react';
import ServiceLayout from 'layout/ServiceLayout';
import Loadable from 'ui-component/Loadable';

const ErrorDefault = Loadable(lazy(() => import('views/error/Default')));
const PaymentDefault = Loadable(lazy(() => import('views/payment/Payment')));

const MainRoutes = {
    path: '/',
    element: <ServiceLayout />,
    children: [
        {
            path: '/payment',
            element: <PaymentDefault />
        },
        {
            path: '*',
            element: <ErrorDefault />
        }
    ]
};

export default MainRoutes;
