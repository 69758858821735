import axios from 'axios';
import ApiConfig from './baseConfig';

export default class Service {
    constructor() {
        axios.interceptors.request.use(
            (config) => {
                config.baseURL = ApiConfig.Root.apiUrl;
                config.headers = {
                    authorization: `Bearer ${this.getToken()}`
                };
                return config;
            },
            (error) => Promise.reject(error)
        );
        axios.interceptors.response.use(
            (response) => {
                if (!response.data.status && response.data.session) {
                    // eslint-disable-next-line
                    alert(response.data.message);
                    this.clearToken();
                    setTimeout(() => {
                        window.location.assign('/');
                    }, 500);
                }
                return response;
            },
            (error) => Promise.reject(error)
        );
    }

    /**
     * auth
     */
    setToken = (data) => localStorage.setItem(ApiConfig.token, data);

    getToken = () => localStorage.getItem(ApiConfig.token);

    clearToken = () => localStorage.removeItem(ApiConfig.token);

    /**
     * auth api
     */
    userLogin = (...args) => axios.post(ApiConfig.request.Login, ...args);

    sessionCheck = () => axios.post(ApiConfig.request.sessionCheck, { token: this.getToken() });

    confirmAuthCode = (...args) => axios.post(ApiConfig.request.confirmAuthCode, ...args);

    submitChangePassword = (...args) => axios.post(ApiConfig.request.submitChangePassword, ...args);
    /**
     *
     * Customer
     */

    submitUser = (args) => axios.post(ApiConfig.request.submitUser, { ...args });

    getCustomer = (parsedFilter, condition, csv) => axios.post(ApiConfig.request.getCustomer, { parsedFilter, condition, csv });

    sendOutMoney = (args) => axios.post(ApiConfig.request.sendOutMoney, { ...args });

    transferBalance = (args) => axios.post(ApiConfig.request.transferBalance, { ...args });

    bulkTransfer = (args) => axios.post(ApiConfig.request.bulkTransfer, { ...args });

    addContract = (args) => axios.post(ApiConfig.request.addContract, { ...args });

    getContract = (args) => axios.post(ApiConfig.request.getContract, { ...args });

    removeContract = (args) => axios.post(ApiConfig.request.removeContract, { ...args });

    sendOutMoneyCheck = (args) => axios.post(ApiConfig.request.sendOutMoneyCheck, { ...args });

    bugFixMoney = (args) => axios.post(ApiConfig.request.bugFixMoney, { ...args });

    verify2FA = (args) => axios.post(ApiConfig.request.verify2FA, { ...args });

    /**
     * Wallet Datas
     */
    getWallets = (args) => axios.post(ApiConfig.request.getWallets, { ...args });

    sendTrx = (args) => axios.post(ApiConfig.request.sendTrx, { ...args });

    sendTrxCheck = (args) => axios.post(ApiConfig.request.sendTrxCheck, { ...args });

    mergeUsdt = (args) => axios.post(ApiConfig.request.mergeUsdt, { ...args });

    getSettingData = (args) => axios.post(ApiConfig.request.getSettingData, { ...args });

    generateNewWallet = (args) => axios.post(ApiConfig.request.generateNewWallet, { ...args });

    updateModeStatus = (args) => axios.post(ApiConfig.request.updateModeStatus, { ...args });

    updateminmaxAmount = (args) => axios.post(ApiConfig.request.updateminmaxAmount, { ...args });

    transferTocold = (args) => axios.post(ApiConfig.request.transferTocold, { ...args });

    exportWallet = (args) => axios.post(ApiConfig.request.exportWallet, { ...args });

    /**
     * Payment page
     */
    parseUrlData = (args) => axios.post(ApiConfig.request.parseUrlData, { ...args });

    parseUrlToken = (args) => axios.post(ApiConfig.request.parseUrlToken, { ...args });

    getWalletAddress = (args) => axios.post(ApiConfig.request.getWalletAddress, { ...args });

    expireAddress = (args) => axios.post(ApiConfig.request.expireAddress, { ...args });

    requestWithdraw = (args) => axios.post(ApiConfig.request.requestWithdraw, { ...args });

    updateAddressTimeOut = (args) => axios.post(ApiConfig.request.updateAddressTimeOut, { ...args });

    cancelWithdrawFunction = (args) => axios.post(ApiConfig.request.cancelWithdrawFunction, { ...args });

    /**
     * Report
     */
    getAdminReport = (args) => axios.post(ApiConfig.request.getAdminReport, { ...args });

    getCustomerReport = (args) => axios.post(ApiConfig.request.getCustomerReport, { ...args });

    getAdminApprovalList = (args) => axios.post(ApiConfig.request.getAdminApprovalList, { ...args });

    getWalletReport = (args) => axios.post(ApiConfig.request.getWalletReport, { ...args });

    getAdminLogReport = (args) => axios.post(ApiConfig.request.getAdminLogReport, { ...args });

    getCustomerList = (args) => axios.post(ApiConfig.request.getCustomerList, { ...args });

    getCustomerIDs = (args) => axios.post(ApiConfig.request.getCustomerIDs, { ...args });

    /**
     * Dashboard
     */
    getAdminBalance = (args) => axios.post(ApiConfig.request.getAdminBalance, { ...args });

    getPrice = (args) => axios.post(ApiConfig.request.getPrice, { ...args });

    getChartsData = (args) => axios.post(ApiConfig.request.getChartsData, { ...args });

    getPieData = (args) => axios.post(ApiConfig.request.getPieData, { ...args });

    getOptionData = (args) => axios.post(ApiConfig.request.getOptionData, { ...args });

    getTodayData = (args) => axios.post(ApiConfig.request.getTodayData, { ...args });

    getTransactionCount = (args) => axios.post(ApiConfig.request.getTransactionCount, { ...args });
}
