import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import AdminRoutes from 'routes/admin';
import CustomerRoutes from 'routes/customer';
import ApproversRoutes from 'routes/approvers';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import baseInit from './redux/action/base/index';
import Contextloading from './ui-component/loading';
import { LoadingProvider } from './layout/Context/loading';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const authInfo = useSelector((state) => state.Auth);
    baseInit(authInfo.userData);
    if (authInfo.isAuth) {
        if (authInfo.userData.type === 'customer' || authInfo.userData.type === 'viewers') {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <LoadingProvider>
                                <Contextloading />
                                <CustomerRoutes />
                            </LoadingProvider>
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        } else if (authInfo.userData.type === 'approvers') {
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <LoadingProvider>
                                <Contextloading />
                                <ApproversRoutes />
                            </LoadingProvider>
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        }
    }
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <LoadingProvider>
                        <Contextloading />
                        <AdminRoutes />
                    </LoadingProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
