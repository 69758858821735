import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import config from 'config/themeConfig';
// import Logo from 'ui-component/Logo';
import IMG_LOGO from '../../../assets/images/users/LOGO-2.png'

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.themeConfig.defaultPath}>
        {/* <Logo /> */}
        <img src={IMG_LOGO} alt='logo' style={{ width: 'auto', height: 'auto' }} />
    </ButtonBase>
);

export default LogoSection;
